/*!
 * Copyright 2024. Elie Nicolas - All Rights Reserved.
 * This file is part of the comuta project.
 */

/*
 *  Import Tailwind.css
 */
@tailwind base;
@tailwind components;
@tailwind utilities;

/*
 *  Font import and style
 */
@import url("https://fonts.googleapis.com/css2?family=Jura:wght@300..700&display=swap");

* {
  @apply text-textPrimary;
  font-family: "Jura", sans-serif;
  font-optical-sizing: auto;
  line-height: normal;
}

/*
 *  General css
 */

html {
  background: rgba(var(--background));
}

body {
  @apply w-screen h-screen;

  #root {
    @apply w-full h-full;
  }
}

/*
 *  Import all scss files
 */
@import 'colors';
@import 'font';
