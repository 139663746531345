/*!
 * Copyright 2024. Elie Nicolas - All Rights Reserved.
 * This file is part of the comuta project.
 */

h1 {
  @apply text-3xl font-bold;
}

h2 {
  @apply text-2xl;
}

h3 {
  @apply text-xl;
}

p {
  white-space: pre-line;
}
