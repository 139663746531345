/*!
 * Copyright 2024. Elie Nicolas - All Rights Reserved.
 * This file is part of the comuta project.
 */

:root {
  --header: 17, 17, 24;
  --primary: 22, 22, 31;
  --primary-contrast: 32, 32, 44;
  --secondary: 32, 32, 44;
  --secondary-contrast: 42, 40, 52;
  --contrast: 22, 22, 31;
  --background: 23, 23, 35;
  --accent: 86, 123, 255;
  --success: 5, 255, 0;
  --error: 255, 0, 0;
  --text-primary: 255, 255, 255;
  --text-secondary: 212, 212, 212;
  --text-accent: 0, 223, 252;
  --text-success: 4, 190, 0;
  --text-error: 255, 0, 0;
  --stroke: 255, 255, 255;
  --stroke-accent: 255, 255, 255;
  --layout: 255, 255, 255;
}

.background {
  background: linear-gradient(180deg, rgba(var(--background)), rgb(var(--background))),
  linear-gradient(180deg, rgba(86, 123, 255, 0.1), transparent),
  repeating-linear-gradient(45deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 10px, transparent 10px, transparent 30px);
  background-blend-mode: color-dodge;
}
